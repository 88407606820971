@font-face {
  font-family: 'icomoon';
  src : url('fonts/icomoon.woff?s9xtgk') format('woff');
  src:  
  url('fonts/icomoon.woff?s9xtgk') format('woff'),
    url('fonts/icomoon.ttf?s9xtgk') format('truetype'),
    url('fonts/icomoon.eot?s9xtgk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.svg?s9xtgk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-skill-precision:before {
  content: "\e900";
}
.icon-skill-resilience:before {
  content: "\e901";
}
.icon-skill-safety:before {
  content: "\e902";
}
.icon-skill-speed:before {
  content: "\e903";
}
.icon-skill-timemanagement:before {
  content: "\e904";
}

@primary-color: #ff4701;@font-size-base: 16px;@text-color: #898989;@font-family: Titillium, Roboto, sans-serif;@code-family: Titillium, Roboto, sans-serif;@layout-body-background: transparent;@layout-header-height: 145px;@avatar-border-radius: 0px;@menu-item-color: #000;@menu-highlight-color: #fff;@menu-item-active-bg: @primary-color;