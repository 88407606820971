// Import ANTD style
// Do not do this. We use modifyVar to override and to load only css part we need
@import '~antd/dist/antd.less';

// Import our fonts
@import '../fonts/Roboto/fonts.css';
@import '../fonts/Titillium/fonts.css';
@import '../fonts/icomoon/style.css';

@import './flags/flags.min.css';

@edition-primary-orange: #ff4701;
@edition-light-green: #a2c89b;
@edition-light-gray: #f2f2f2;
@edition-medium-gray: #f3f3f3;
@edition-very-light-gray: #f9f9f9;

@light-color-vision: #3DB5A1;
@dark-color-vision: #009688;
@light-color-vulcan: #ff802b;
@dark-color-vulcan: #fe5d02;
@grey-vulcan: #898989;
@dark-gray: #555555;
@mid-gray: #ccc;
@light-gray: #ececec;
@red-vulcan: #ff001d;
@yellow-vulcan: #e0b217;
@carrousel-arrow-size: 30px;
@vulcan-bg-color: #F3F3F3;

@vulcan-font-size: 18px;


@wide-screen: "only screen and (min-width: 1441px)";
@mid-screen: "only screen and (min-width: 1025px) and (max-width: 1440px) and (min-height: 770px)";
@tablets-screen: "only screen and (min-width: 768px) and (max-width: 991px)";


@drag-handle-width: 10px;
@drag-handle-height: 10;
@drag-bar-size: 6px;

// Handle color/border.
@drag-handle-background-colour: rgba(0, 0, 0, 0.2) !important;
@drag-handle-border: 1px solid rgba(255, 255, 255, 0.7) !important;

@vulcan-box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

body {
	min-height: 100%;
	background-color: #f3f3f3;
}

h1 {
	font-size: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: @grey-vulcan;
	font-weight: 600;
}

code {
	background-color: #eee;
	border-radius: 3px;
	font-family: courier, monospace;
	padding: 0 3px;
}

// Main content
#app {
	height: 100%;
	// overflow: auto;
}

// Layout
// .ant-layout {
// 	.ant-layout-content {
// 		height: 100%;
// 	}
// }


.full-centered {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.justify-content-center {
	display: flex;
	justify-content: center;
}

.justify-content-space-between {
	display: flex;
	justify-content: space-between;
}

.justify-content-end {
	display: flex;
	justify-content: flex-end;
}

.align-items-center {
	display: flex;
	align-items: center;
}

.align-items-baseline {
	display: flex;
	align-items: baseline;
}

.alignt-items-start {
	display: flex;
	align-items: flex-start;
}

.flex-wrap {
	flex-wrap: wrap;
}

.height-max {
	height: 100%;
}

.min-height-max {
	min-height: 100%;
}

.width-max {
	width: 100%;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center !important;
}

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.vulcan-box-shadow {
	box-shadow: @vulcan-box-shadow;
}

.text-primary {
	color: @edition-primary-orange;
}

.text-grey {
	color: @grey-vulcan;
}

.text-dark-grey {
	color: @dark-gray;
}

.text-alert {
	color: @red-vulcan;
}

.text-warning {
	color: @yellow-vulcan;
}

.row-table-disabled {
	background-color: #e8e8e8;
	pointer-events: none;
}

.font-bold {
	font-weight: bold;
}

.font-semi-bold {
	font-weight: 550
}

.line-height {
	line-height: 2.5;
}

.page-title-vulcan-ed {
	text-transform: uppercase;
	color: @dark-color-vulcan;
	padding: 10px 0 20px 0;
	font-weight: 400;
	font-size: 20px;

	@media @wide-screen {
		font-size: 24px;
		padding-bottom: 15px;
	}
}

.ar-uploader {
	background-color: white;
	text-align: center;
}

.page-title {
	color: #666666;
	font-weight: 200;
	font-size: 25px;
	margin-bottom: 0px;

	@media @wide-screen {
		font-size: 32px;
	}

	@media @mid-screen {
		font-size: 28px;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.bg-transparent {
	background-color: transparent;
}

.bg-error {
	background-color: #f4eae9;
}

.bg-warning {
	background-color: #efebdc;
}

.bg-grey {
	background-color: #e8e8e8;
}

.bg-light-orange {
	background-color: #fff1e6;
}

.bg-dark-grey {
	background-color: @dark-gray;
}

.bg-vulcan-red {
	background-color: @red-vulcan;
}

.requiredMark {
	color: red;
}


.text-error {
	color: @red-vulcan;
}

.information-tooltip-icon {
	padding-left: 5px;
	font-size: 14px;

}

.information-tooltip {
	.ant-tooltip-inner {
		background-color: white;
		color: @grey-vulcan;
		font-size: 14px;
	}
}

.ant-tooltip {
	max-width: 500px;
}

.list-style-type-none {
	list-style-type: none;
}

.vertical-align-list {
	li {
		float: left;
		height: 100%;
		display: flex;
	}

}

.primary-color-hover:hover {
	color: @edition-primary-orange;
	transition: 0.5s;
}

//
// Vulcan
//

.vulcan-bg {
	height: 100%;
	background: url("../images/background/patterns/dots.png") repeat;
}

.vulcan-fbg {
	height: 100%;
	background: linear-gradient(180deg, #ececec, rgba(0, 0, 0, 0));
}

.pos-absolute {
	position: absolute;
}

.absolute-align-left {
	left: 0;
}

.absolute-align-center {
	left: 50%;
}

.absolute-align-right {
	right: 0;
}

.border-bottom-solid {
	border-bottom: 2px solid @mid-gray;
}

.border-color-mid-gray {
	border-color: @mid-gray;
}

.svg-primary-color {
	path {
		fill: @edition-primary-orange
	}
}

.svg-mid-gray {
	path {
		fill: @grey-vulcan
	}
}

.svg-white {
	path {
		fill: white;
	}
}

.svg-light-gray {
	path {
		fill: @edition-light-gray
	}
}

.vulcan-dark-color {
	color: @dark-color-vulcan;
}

.link {
	font-weight: 700;
	cursor: pointer;
	color: @edition-primary-orange;
}

.centering {
	margin: 0 auto;
}

::placeholder {
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	color: rgba(0, 0, 0, 0.45) !important;
	opacity: 1;
	/* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: rgba(0, 0, 0, 0.65) !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: rgba(0, 0, 0, 0.65) !important;
}

.bg-border-color-mid-gray {
	background-color: @mid-gray;
}

.bg-color-light-gray {
	background-color: @edition-medium-gray;
}

.add-item-wrapper {
	.add-item-border {
		border: 1px dashed @mid-gray;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			fill: @edition-primary-orange;
		}

		.edit-icon {
			path {
				fill: white;
			}
		}
	}
}

///////////////////////////////////////////////////////////////////////////
// Common stuff

// ---- List panel
.vulcan-list-panel {

	background-color: white;
	box-shadow: @vulcan-box-shadow;
	height: 100%;
	padding-top: 10px;

	// Tiles in general
	.vulcan-list-panel-tile,
	.vulcan-list-panel-add-tile {
		position: relative;
		display: flex;
		cursor: pointer;
		padding-left: 20px;
		// height: 30px;
		width: 100%;
	}

	// Add tile specifics
	.vulcan-list-panel-add-tile {

		// Add tile icon
		//.anticon {
		color: @edition-primary-orange;
		//}

		// Add tile hover
		:hover span:not(.anticon) {
			color: @edition-primary-orange;
		}
	}

	// Content
	.vulcan-list-panel-content {
		margin-top: 5px;
		overflow: auto;

		// Add tile text
		.vulcan-list-panel-add-tile span:not(.anticon) {
			color: @mid-gray;
		}

		// Item tile
		.vulcan-list-panel-tile {

			border-top: 2px solid transparent;
			border-bottom: 2px solid transparent;

			// Odd tile
			&:nth-child(odd) {
				background-color: @light-gray;
			}

			// Active/selected tile
			&.active {
				background-color: @edition-primary-orange;
				color: white;
			}

			// Hover over tile
			&:hover:not(.active) .ant-space-item:not(:last-child) {
				color: @edition-primary-orange;
			}

			// The delete icon
			.ant-space-item:last-child {
				position: absolute;
				right: 5px;

				:hover {
					color: @edition-primary-orange;
				}
			}

			// Dash line appearing when sorting an element in the list (top)
			&.drop-target-top {
				border-top: 2px dashed @edition-primary-orange;
			}

			// Same but bottom
			&.drop-target-bottom {
				border-bottom: 2px dashed @edition-primary-orange;
			}
		}
	}
}

// Basic vulcan pane with box shadow
.vulcan-pane {
	background-color: white;
	box-shadow: @vulcan-box-shadow;
	padding: 20px 40px;
	margin-bottom: 40px;

	hr {
		background-color: @edition-primary-orange;
		height: 1px;
		border: 0;
	}
}

.vulcan-popover .ant-popover-inner-content {
	max-width: 500px;
}

// Basic border
.vulcan-outline {
	border: 1px solid @edition-primary-orange;
}

// ANTD MODAL FOOTER
.confirm-modal-fotter .ant-modal-footer {
	border-top: 0;
}

// ANTD COMPONENT TREE
.ant-tree {
	background-color: transparent;
}

// TABS WITH SELECTION COLUMN
// Need to use ColumnWidth in <Table> rowSelection

.vulcan-table-with-selection .ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th,
.vulcan-table-with-selection .ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td {
	padding: 12px 0;
}

// ------------ Editable Table

// Editable cell
.vulcan-editable-cell {
	position: relative;
}

// Editable cell value wrapper
.vulcan-editable-cell-value-wrap {
	padding: 5px 12px;
	cursor: pointer;
	min-height: 35px;

	// Text ellipsis
	/* width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; */

	&:hover {
		padding: 4px 11px;
		border: 1px solid #d9d9d9;
		border-radius: 2px;
	}
}

// For sticky column to not be transparent in background
.ant-table-tbody>tr.ant-table-row.vulcan-editable-row:hover>td,
.ant-table-tbody>tr.vulcan-editable-row>td.ant-table-cell-row-hover {
	background-color: white;
}

.lang-select {
	cursor: pointer;

	.ant-select-selection {
		border: none;
	}
}

.join-form-label {
	font-size: 16px;
	font-weight: 400;
	color: @grey-vulcan;

	.ant-form-item-label>label {
		font-size: 16px;
		font-weight: 400;
		color: @grey-vulcan;
	}
}

////////////////////////////
// Upload image component

.vulcan-upload-image>.ant-upload {
	overflow: hidden;

	// Preview image
	&.ant-upload-select-picture-card>.ant-upload>img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

.vulcan-logo>.ant-upload {
	border-radius: 50%;
}

// Old upload image stuff

:not(.vulcan-upload-image)>.ant-upload.ant-upload-select-picture-card {
	width: 50px;
	height: 50px;
}

:not(.vulcan-upload-image)>.ant-upload.ant-upload-select-picture-card>.ant-upload>img {
	max-height: 50px;
	max-width: 50px;
}


.ar-editor-wrapper {
	text-align: center;
}

.ar-border-radius {
	border-radius: 20px;
}

.has-error input {
	border-color: red !important;
}

.informations-tile {
	background-color: white;
	border: 1px solid @edition-primary-orange;
	box-shadow: @vulcan-box-shadow;

	color: @grey-vulcan;
	padding: 10px;
	margin-bottom: 20px;

	a {
		font-weight: bold;
	}
}

// USER INFOS

.ant-input {
	border-radius: 4px;
}

.ant-btn {
	border-radius: 4px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-radius: 4px;
}

.items-list-wrapper {
	width: 100%;
	clear: right;
	position: relative;
}

.subtitle {
	//  margin-top: 1em;
	color: @grey-vulcan;
}

// CGU

.cgu {
	padding: 20px;

	ul {
		list-style-type: upper-alpha;

		li {
			margin-top: 1em;
		}
	}
}

/** OVERWRITE EXPAND ICON **/
.ant-table-row-expand-icon {

	position: relative;
	border: none;
	background-color: transparent;

	&::before {
		display: none;
	}

	&::after {
		position: absolute;
		top: 0;
		left: 0;
		background: transparent;
		width: 100%;
		height: 100%;
	}

	&.ant-table-row-expand-icon-collapsed:after {
		content: "\23F7";
		font-family: "anticon" !important;
		display: inline-block;
		transform: rotate(-90deg);
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	&.ant-table-row-expand-icon-expanded:after {
		content: "\23F7";
		font-family: "anticon" !important;
		display: inline-block;
		transform: rotate(0deg);
		transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

}

.ant-table {
	background-color: transparent;
}

.items-list-table .ant-table-tbody>tr:hover>td {
	background-color: #fff1e6 !important;
}

.items-list-table thead {
	text-transform: uppercase;

	tr th {
		background-color: @edition-light-gray;
		border-bottom-color: @mid-gray;
	}
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	content: none;
}

.items-list-table .ant-table-thead>tr>th[colspan] {
	text-align: left;
	border-bottom: 1px solid @mid-gray;
}

.ant-table-thead>tr>th.items-list-col,
.ant-table-tbody>tr>td {
	word-break: normal;
	padding: 0px;
}

.items-list-col>.ant-table-row-expand-icon {
	float: left;
	margin-top: 6px;
}

.items-list-column-title {
	cursor: pointer;

	&:hover,
	&:active {
		color: @edition-primary-orange;
	}
}

.items-list-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	height: 10px;
}

.items-list-table .ant-table-column-sorter {
	height: 19px;
}

.items-list-table :root .ant-table-column-sorter .anticon-caret-up,
:root .ant-table-column-sorter .anticon-caret-down {
	font-size: 19px;
}

.items-list-table :root .ant-table-column-sorter .anticon-caret-up,
:root .ant-table-column-sorter .anticon-caret-up {
	font-size: 19px;
}

.items-list-table .items-list-table .ant-table-column-sorter-up,
.ant-table-column-sorter-down {
	height: 8px;
}

.items-list-table .ant-table-column-sorter {
	color: @mid-gray;
}

.items-list-table .ant-table-tbody>tr:hover>td {
	background-color: white;
}

.levels-list-col {
	padding: 2px;
}

.ant-popover-title {
	text-align: center;
	min-width: 100px;
}

.round-btn-bg {
	background-color: @edition-primary-orange;
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	transition: box-shadow 0.6s ease-in-out;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 10px 0 @edition-primary-orange;
		transition: box-shadow 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
	}
}

@round-icon-size: 40px;
@round-icon-svg-size: 25px; // check in component:  SVGInline width

.round-icon-bg-wrapper {
	width: @round-icon-size;
	display: inline-block;
	position: relative;
}

.round-icon-bg {
	background-color: #fff;
	width: @round-icon-size;
	height: @round-icon-size;
	display: inline-block;
	border-radius: 50%;
	position: absolute;
	top: calc(-@round-icon-size/2 - (@round-icon-size/2 - @round-icon-svg-size/2));

	.SVGInline {
		position: absolute;
		top: calc(@round-icon-size/2 - @round-icon-svg-size/2);
		left: calc(@round-icon-size/2 - @round-icon-svg-size/2);

		svg {
			width: @round-icon-svg-size;
			height: @round-icon-svg-size;

			path {
				fill: @grey-vulcan;
			}
		}
	}
}

/*.items-list-table .ant-table-small {
	border: none!important;
}*/

.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>.exercises {
	display: flex;
	padding: 10px;
}

.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>.items-list-col {

	.ant-btn {
		box-shadow: none;
	}

	.SVGInline {

		display: inline-block;

		.SVGInline-svg {
			width: 32px;
			height: 32px;

			path {
				fill: @grey-vulcan;
			}
		}
	}
}

// .ant-table-thead>tr>th.items-list-col,
// .ant-table-tbody>tr>td {
// 	svg {
// 		display: inline-block;
// 		width: 32px;
// 		height: 32px;

// 		path {
// 			fill: @grey-vulcan;
// 		}

// 	}
// }

.levels .ant-table-content>table>.ant-table-thead>tr>.ant-table-cell.levels-list-col {
	border: none;
	background-color: transparent;

	&:hover {
		background-color: transparent !important;
	}
}

.levels>.ant-table-content>table>.ant-table-thead>tr>.ant-table-cell.ant-table-selection-column {
	border: none;
}

.levels>.ant-table-content>table>.ant-table-tbody>tr>.ant-table-cell.ant-table-selection-column {
	background-color: transparent;
}

.levels .ant-table-content>table>.ant-table-tbody>tr>.ant-table-cell.levels-list-col {
	border: none;
	background-color: transparent
}

.ant-table-content>table>.ant-table-tbody>tr>th>.ant-table-cell.levels-list-col {
	padding: 0;
	border: none;
	background-color: transparent
}

.ant-table-tbody>tr.ant-table-row:hover>td,
.ant-table-tbody>tr>td.ant-table-cell-row-hover {
	background-color: transparent;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
	background-color: transparent;
}

.items-list-tools-icons {
	cursor: pointer;
	color: @edition-primary-orange;

	&:hover {
		transition-duration: 0.3s;
		color: #ff6d29;
	}
}

.transparent-input {
	background-color: transparent;
	border: none;
}

.transparent-input:hover {
	background-color: white;
}

.transparent-input:focus {
	border: none !important;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
	background-color: white;
}

// INDETERMINATE
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
	content: ' ';
	-webkit-transform: translate(-50%, -50%) scale(1);
	-ms-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	border: 0;
	left: 50%;
	top: 50%;
	width: 8px;
	height: 8px;
	// background-color: @edition-primary-orange;
	opacity: 1;
}


// NESTED TABLE

.dashboard-nested-table {

	&.results-table {

		.ant-table table {
			border-spacing: 0 20px;
			border-collapse: separate;
		}

		.ant-table-thead>tr>th,
		.ant-table-tbody>tr>td {
			word-break: normal;
			padding-left: 8px;
		}

		.items-list-table thead tr th {
			background-color: transparent;
		}

		.ant-table-thead>tr>th.items-list-col {
			padding: 16px 8px;
		}

		.ant-table-content>table>.ant-table-tbody {

			>.ant-table-expanded-row>td {
				padding: 0;
			}

			>tr.ant-table-row {
				height: 100px;
				background-color: @edition-very-light-gray;
				position: relative;

				td {

					border-top: 1px solid #e8e8e8;

					&.items-list-col {
						padding: 16px 16px;
						word-break: break-all;
					}

					&.ant-table-row-expand-icon-cell {

						position: absolute;
						z-index: 990;
						width: 100%;
						height: 100px;
						visibility: hidden;

						&:hover {
							background-color: white;
						}

						.ant-table-row-expand-icon {
							position: absolute;
							bottom: 0;
							visibility: visible;

							&.ant-table-row-expand-icon-collapsed:after {
								background-color: @edition-primary-orange;
								color: white;
								content: "\23F7";
								font-family: "anticon" !important;
								display: flex;
								align-items: center;
								justify-content: center;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								transform: rotate(0deg);
								transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							}

							&.ant-table-row-expand-icon-expanded:after {
								background-color: @edition-primary-orange;
								color: white;
								content: "\23F7";
								font-family: "anticon" !important;
								display: flex;
								align-items: center;
								justify-content: center;
								width: 30px;
								height: 30px;
								border-radius: 50%;
								transform: rotate(180deg);
								transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							}
						}
					}
				}

				&:hover>td {
					background-color: white;
				}

			}


		}

		.ant-table-body .ant-table-thead {
			display: none;
		}

		.ant-table-expanded-row {
			background: transparent;
			border-spacing: 0;

			&:hover>td {
				background: transparent;
			}

			.bg-error {
				background-color: rgb(252, 234, 232) !important;
			}

			td {
				border: none;
			}
		}

		.nested-users-list {

			.ant-table table {
				border-spacing: 0;

			}

			.ant-table .ant-table-content {

				.ant-table-thead {
					display: table-header-group;
					text-transform: uppercase;

					tr>th {
						background-color: #f2f2f2;
					}
				}

				.ant-table-tbody>tr {
					background-color: #f2f2f2;
					height: auto;

					&:hover>td {
						background-color: white;
					}
				}
			}

		}
	}
}

.nested-exercise-table {
	.ant-table-content>table>.ant-table-tbody {
		>.ant-table-expanded-row>.ant-table-cell {
			background-color: #f2f2f2;

			&:hover {
				background-color: transparent !important;
			}

		}


		.ant-table-expanded-row {
			.disable-table-row {
				background-color: #dcdcdc;
				pointer-events: none;
			}
		}

	}
}

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
	margin: 0;
}

.result-progress .ant-progress-inner {
	border: 1px solid #f2f2f2 !important;
	border-radius: 0;
}

.result-progress .ant-progress-bg {
	border-radius: 0;
}

//

.transparent-button {
	border: none;
	background-color: transparent;
	font-size: 13px;
	box-shadow: none;

	&:hover {
		background-color: transparent;
		color: @light-color-vulcan;
	}

	&:visited {
		background-color: transparent;
		color: @light-color-vulcan;
	}

	&:focus {
		background-color: transparent;
		color: @light-color-vulcan;
		outline: none;
	}

	&:active {
		background-color: transparent;
		color: @light-color-vulcan;
		outline: none;
	}
}

.tagInput-wrapper {
	ul {
		display: inline-block;
		width: 100%;
		min-height: 30px;
		padding: 0 2px;
		border: 1px solid #d9d9d9;
		font-family: Helvetica, sans-serif;
		border-radius: 4px;
		cursor: pointer;

		li {
			display: inline-block;
			padding: 0 3px;
			border: 1px solid @edition-light-gray;
			font-family: Helvetica, sans-serif;
			font-size: 12px;
			border-radius: 3px;
			margin-right: 5px;
			cursor: pointer;

			.anticon {
				padding: 0 3px;
			}
		}
	}

	input {
		outline: none;
		border: none;
		font-size: 14px;
		font-family: Helvetica, sans-serif;
	}

}



///////////////////////////////////////////////////////////////////////////////
// Apps

@app-circle-size: 100px;

.app {
	cursor: pointer;

	&:hover {

		.app-circle {
			border: 3px solid @dark-color-vision;
		}

		.app-name {
			opacity: 1;
		}
	}
}

.app-circle {
	background-color: #fff;
	border-radius: 50%;
	height: @app-circle-size;
	width: @app-circle-size;
	transition: 0.2s ease-in-out;
}

.app-name {
	margin-top: 10px;
	font-size: 30px;
	text-transform: uppercase;
	opacity: 0.7;
	transition: 0.2s ease-in-out;
}

.vision {
	& .app-circle {
		filter: hue-rotate(180deg) contrast(120%);
	}
}

/////////////////////////////////////////////////////////////////////////////
// NavBar

.notifications-popover {

	// Popover content
	.ant-popover-inner-content {
		max-height: 500px;
		overflow-y: auto;
	}

	// Popover title
	.ant-popover-title {
		background-color: #f9f9f9;
	}
}

// Notification tile
.notification-tile {

	background-color: #f9f9f9;

	.ant-card-body {
		padding-right: 44px;
	}

	&-actions {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}

.notification-tile-dot {

	width: 10px;
	height: 10px;
	margin-top: 7px;
	margin-right: 5px;
	border-radius: 50%;

	&-INFO {
		background-color: @edition-light-green;
	}

	&-WARNING {
		background-color: @yellow-vulcan;
	}

	&-ERROR {
		background-color: @light-color-vulcan;
	}

	&-CRITICAL {
		background-color: @red-vulcan;
	}
}

// Center user icon in circle
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
	margin-right: 0;
}

/////////////////////////////////////////////////////////////////////////////
// Footer
.v_footer {
	color: white;
	position: absolute;
	bottom: 0;
	width: 240px; // utiliser la même largeur que le Sider
	text-align: center;
	padding: 10px;
}

//////////////////////////////////////////////////////////////////////////
// HOME PAGE
.home-page-title {
	position: absolute;
	top: 20px;
	left: 50px;
}


//////////////////////////////////////////////////////////////////////////
// TRAINEE PROFILE

.trainee-infos-sider {
	padding: 0 0 0 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.trainee-profile-sider {
	background-color: #e6e6e8;
	width: 70%;
	min-width: 320px;
}

.trainee-infos {
	font-size: 25px;
	line-height: 0.7;
	margin-bottom: 10px;

	@media @wide-screen {
		font-size: 35px;
	}

	@media @mid-screen {
		font-size: 30px;
	}
}

.trainee-infos.p {
	line-height: 0.7px;
}

.trainee-fname {
	color: #666666;
}

.trainee-lname {
	color: #2b2b2b;
	font-weight: bold;
}

.class-name {
	font-size: 24px;
	line-height: 1.5em;
	-webkit-text-size-adjust: auto;
	-moz-text-size-adjust: auto;
	-ms-text-size-adjust: auto;
}

.cursus-name {
	font-size: 16px;
	line-height: 1em;
	padding-right: 50px;
	-webkit-text-size-adjust: auto;
	-moz-text-size-adjust: auto;
	-ms-text-size-adjust: auto;

	@media @wide-screen {
		font-size: 24px;
	}

	@media @mid-screen {
		font-size: 18px;
	}
}

.cursus-infos {
	padding-top: 10px;
	font-size: 16px;

	@media @wide-screen {
		font-size: 20px;
	}
}

.trainee-category-title {
	color: @dark-gray;
	font-size: 25px;
	font-weight: 300;

	@media @mid-screen {
		font-size: 28px;
	}

	@media @wide-screen {
		font-size: 32px;
	}
}

.trophies-container {
	padding-bottom: 5px;
}


//Gauges

.gauge-align {
	text-align: center;
	display: inline-block;
	width: 20%;
	margin-right: 30px;
	vertical-align: top;
}

.gauge-title {
	font-size: 16px;

	@media @wide-screen {
		font-size: 24px;
	}
}

.gauge-background {
	display: inline-block;
	position: relative;
	width: 22px;
	height: 150px;
	background-color: #ffffff;
	border: 1px solid #ffffff;
	border-radius: 5px;

	@media @wide-screen {
		height: 250px;
		width: 38px;
	}

	@media @mid-screen {
		height: 200px;
		width: 30px;
	}
}

.gauge-foreground-from-bottom {
	position: absolute;
	left: 5%;
	width: 90%;
	bottom: 0px;
	background-color: @light-color-vision ;
	border-radius: 5px;
	border-top: 1px solid #ffffff;
}

.gauge-foreground-from-top {
	position: absolute;
	left: 5%;
	width: 90%;
	top: 0px;
	background-color: @dark-gray;
	border-radius: 5px;
	border-bottom: 1px solid #ffffff;
}

@gauge-border-radius: 5px;

.horizontal-gauge-container {
	z-index: 2;
	width: 100%;
	color: #fff;
	display: inline-flex;
	cursor: pointer;
	//    border-bottom: 1px dotted @mid-gray;
	//    border-radius: @gauge-border-radius;
}

@horizontal-gauge-margin-left : 10px;

.horizontal-gauge {
	padding: 0 5px;
	height: 20px;
	border-radius: @gauge-border-radius;
	line-height: 20px;
	z-index: 1;
	//   margin-left: @horizontal-gauge-margin-left;

	@media @mid-screen {
		height: 22px;
		line-height: 22px;
	}

	@media @wide-screen {
		height: 35px;
		line-height: 35px;
	}
}

.horizontal-gauge-line-center {
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: @mid-gray;
	top: 50%;
}

.horizontal-gauge-try-date {
	font-size: 14px;

	@media @wide-screen {
		font-size: 18px;
	}
}


.horizontal-gauge-label {
	margin-right: 10px;
}


// Trainee History


.trainee-history-content {
	/*
	&::-webkit-scrollbar {
		display: none;
	}
	overflow-y: scroll;
	*/
	overflow: auto;
	max-height: 100%;
	margin-right: -100px;
	margin-left: 10px;
	padding-right: 100px;
}


.trainee-history-try-row {
	margin: 20px 0;
	max-height: 4.9em; // with small resolution, there are more lines for the traineesListcises names. So we need more vertical space.

	&:last-child {
		margin-top: 20px;
		margin-bottom: 0;
	}
}


@trainee-history-elements-size: 35px;

.trainee-history-try-score {
	position: absolute;
	color: #fff;
	border-radius: 50%;
	width: @trainee-history-elements-size;
	height: @trainee-history-elements-size;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	cursor: pointer;
	left: -2px;

	@media @wide-screen {
		font-size: 16px;
	}

	@media @mid-screen {
		font-size: 16px;
	}
}

.trainee-history-simulator-icon {
	position: absolute;
	margin-left: @trainee-history-elements-size + 5px;
	width: @trainee-history-elements-size;
	height: @trainee-history-elements-size;
	filter: invert(100%);
	cursor: pointer;

	@media @wide-screen {
		font-size: 16px;
	}

}

.trainee-history-try-traineesListcise-name {
	margin-left: (@trainee-history-elements-size + 5px) * 2;
	font-size: 14px;
	cursor: pointer;
	line-height: 14px;

	&.active {
		color: @dark-color-vision;
	}

	@media @mid-screen {
		font-size: 16px;
		line-height: 18px;
	}

	@media @wide-screen {
		font-size: 16px;
		line-height: 18px;
	}
}

.trainee-history-try-date {
	font-size: 14px;
	letter-spacing: -0.5px;

	@media @mid-screen {
		font-size: 16px;
	}

	@media @wide-screen {
		font-size: 16px;
	}
}

@trainee-history-vertical-line-width: 2px;

.trainee-history-vertical-line {
	height: 100%;
	position: absolute;
	width: @trainee-history-vertical-line-width;
	background: @mid-gray;
	top: -25px;
	left: @horizontal-gauge-margin-left + 4px;
	z-index: 0;
}

@very-high-max-height: 10000px;

.trainee-history-tries-details {
	margin-top: 0px;
	position: relative;
	// overflow: hidden;
	// transition: 0.2s;
	width: 100%;
	height: 0px;

	&.collapsed {
		height: auto;
		//   margin-top: 45px;
		margin-left: 18px;
		margin-bottom: 5px;
	}
}



.trainee-history-try-details {
	background-color: #e6e6e8;
	width: 100%;
	overflow: auto;
	position: absolute;
	z-index: 1;

	.trainee-history-try-details-close {
		font-size: 30px;
		position: absolute;
		margin: 10px;
		color: @dark-gray;
		right: 0;

		&:hover {
			color: @light-color-vision;
		}
	}

	.trainee-history-try-details-wrapper {
		font-size: @vulcan-font-size;
		font-weight: 400;
	}

	.trainee-history-try-simulator-icon {
		width: 25px;
		height: 25px;
		filter: invert(100%);
		margin-right: 5px;
	}

	.trainee-history-try-traineesListcise-name {
		color: @dark-color-vision;
		margin-left: 0;
	}

	.trainee-history-try-traineesListcise-status {
		color: @dark-color-vision;
		font-size: 30px;
	}

	.trainee-history-try-section-titles {
		font-size: 20px;
		font-weight: 600;
	}

	.cursus-type {
		width: 35px;
		height: 35px;
		margin-right: 5px;
	}

	.learning-vars-name {
		line-height: 18px;
		font-size: 14px;
	}

	.learning-vars-cols-vertical-line {
		height: 100%;
		position: absolute;
		width: 1px;
		background: @mid-gray;
		top: 0;
		left: calc((50%) - 0.5px);
	}
}

.difficulty-circles {
	width: @vulcan-font-size;
	height: @vulcan-font-size;
	border-radius: 50%;
	background-color: @dark-color-vision;
	border: 2px solid @dark-color-vision;
	display: inline-block;
	margin-right: 5px;
}

.edition-difficulty-circles {
	border-radius: 50%;
	background-color: @grey-vulcan;
	border: 2px solid @grey-vulcan;
	display: inline-block;
}

// Trainee Radar
.trainee-radar-container {
	margin-left: 180px;
	margin-top: 100px;
}

.learning-path-icon {
	filter: grayscale(100%);
	transition: 0.2s ease-in-out;

	&:hover {
		filter: grayscale(0%);
	}
}

.training-path-name {
	font-size: 28px;
}

.training-path-row-title {
	padding: 10px 0 0 30px;
}

.text-align-center {
	text-align: center;
}


.nav-btn {
	margin: 5px;
	width: 100px;
}

.training-path-trainee-infos {
	font-size: 25px;
	position: absolute;
	right: 250px;
}

.slick-next {
	right: 10px;
	height: @carrousel-arrow-size;
	width: @carrousel-arrow-size;
}

.slick-prev {
	left: 10px;
	width: @carrousel-arrow-size;
	height: @carrousel-arrow-size;
	width: @carrousel-arrow-size;
	z-index: 1;
}

.slick-prev:before,
.slick-next:before {
	color: @mid-gray;
	font-size: @carrousel-arrow-size;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
//// VULCAN (DASHBOARD previously called "EDITION")

.page-row {
	padding: 10px 30px 0 30px;
	cursor: pointer;
}

.page-table {
	width: 100%;
	border-collapse: collapse;
}

.page-cell {
	border-bottom: 1px solid @mid-gray;
	height: 60px;
	vertical-align: middle;
}


.table-cell-align-right {
	text-align: right;
}


.page-cell-flex-container {
	display: flex;
	justify-content: center;
}

.cell-hover:hover {
	color: @light-color-vision;
}

.float-right {
	float: right;
}

.page-number {
	padding-left: 10px;
}

.edition-primary-orange {
	color: @edition-primary-orange;
}

// TE USER
.te-user-container {
	margin-top: 20px;
}

.ant-confirm-body .ant-confirm-title {
	overflow: hidden;
}

.SVGInline {
	line-height: 0;
}

.ant-adjust-hue-nested-loading.height-max {
	.ant-adjust-hue-container {
		height: 100%;
	}
}

.ant-adjust-hue-nested-loading>div>.ant-adjust-hue.height-max {
	max-height: initial;
}

.svg-white {
	svg path {
		fill: #fff;
	}
}

.svg-grey {
	svg path {
		fill: @grey-vulcan;
	}
}

.ant-form-item-label label {
	color: @grey-vulcan;
}

//////////////////////////////////////////////
// LAYOUT

.vulcan-layout {
	min-height: 100%;
	background-color: @edition-medium-gray;

}

.vulcan-header {
	height: 120px;
	padding: 0;
	background: transparent;
}

.vulcan-content {
	padding-right: 50px;
	padding-left: 50px;
	padding-bottom: 50px;

}

.vulcan-dashboard-sider {
	background: transparent;
	height: 100vh;
	position: sticky;
	overflow: auto;
	top: 0;
	left: 0;

}

.vulcan-dashboard-sider::-webkit-scrollbar {
	display: none;
}

.vulcan-dashboard-page-wrapper {
	padding: 0 100px 100px 100px;

	@media @tablets-screen {
		padding: 50px;
	}
}


//////////////////////////////////////////////
// APPS

.vulcan-apps {

	background-color: @edition-medium-gray;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	height: 100%;

	.app-title {
		margin-top: 20px;
		text-align: center;
		text-transform: uppercase;
		color: #aeaeae;
		font-size: 44pt;
		transition: 0.2s ease-in-out;
	}

	.app-wrapper {

		&:hover {

			.vulcan-dashboard {
				background-color: @edition-primary-orange;

				svg,
				img {
					width: 180px;
					height: 180px;
				}
			}

			.vulcan-vision {
				background-color: @dark-color-vision;

				svg,
				img {
					width: 180px;
					height: 180px;
				}
			}

			.app-title {
				color: #8c8c8c;
			}
		}
	}

	.app {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.2s ease-in-out;
		-webkit-box-shadow: 0px 25px 150px -35px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 25px 150px -35px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 25px 150px -35px rgba(0, 0, 0, 0.75);

		svg,
		img {
			width: 150px;
			height: 150px;
			transition: 0.2s ease-in-out;
		}

		&.vulcan-dashboard {
			background-color: @edition-primary-orange;

			path {
				fill: #fff;
			}
		}

		&.vulcan-vision {
			background-color: #57847b;
		}
	}
}

.vulcan-btn {
	width: 143px;
	height: 38px;
}

.vulcan-modal-mask {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, .5);
	height: 100%;
	z-index: 1000;
}

.vulcan-modal-box {
	background-color: white;
	border-radius: 5px;
	position: fixed;
	z-index: 1000;
	padding: 30px 30px 20px 30px;
	box-shadow: 3px 3px 10px 0px #00000029;
}

.ant-modal-content-custom {
	border-radius: 4px;

	.exercises-title-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		// height: 50px;

		.exercises-title {
			float: left;
			margin: 0;
			padding-right: 10px;
		}
	}

	ul {
		list-style-type: none;
	}

	.ant-tree {
		background-color: transparent
	}

	.ant-modal-body {
		border-radius: 4px;
	}

	.ant-modal-footer {
		background-color: @edition-light-gray;
		text-align: center;
		border-top: none;

		button {
			width: 15%;
		}
	}
}

///////////////////////////////////////////////
// Choose Organization
.vulcan-choose-organization {

	background-color: @edition-medium-gray;
	display: flex;
	align-items: center;
	justify-content: center;

	.row-title {
		padding: 50px 0;
	}

	.title {
		color: @grey-vulcan;
		font-size: 22px;
	}

	.orga-name {
		color: @grey-vulcan;
		padding-top: 15px;
		font-size: 24px;
	}

	.orga {
		color: white;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.2s ease-in-out;
		margin: auto;
		// -webkit-box-shadow: 0px 25px 150px -35px rgba(0,0,0,0.75);
		// -moz-box-shadow: 0px 25px 150px -35px rgba(0,0,0,0.75);
		// box-shadow: 0px 25px 150px -35px rgba(0,0,0,0.75);

		.clip-img {
			// clip-path: circle(95px at center); does not work for Edge
			height: 100%;
			// width: 100%;
			border-radius: 50%;
			object-fit: contain;
		}
	}

	.add-orga-border {
		stroke-width: 5;
		stroke-dasharray: 31, 16;
		fill: none;
		stroke: @grey-vulcan;
		opacity: 0.5;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.2s ease-in-out;

		.plus-icon {
			font-size: 70px;
			color: @grey-vulcan;
			opacity: 0.5;
		}

		&:hover {
			opacity: 0.8;

			.plus-icon {
				opacity: 0.8;
			}
		}
	}

}

//////////////////////////////////////////////
// Color Picker
.color-picker {
	position: absolute;
	z-index: 100;
}

.color-picker-btn {
	width: 50px;
	height: 50px;
	border: 1px dashed #d9d9d9;
	cursor: pointer,
}

.color-picker-cover {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 99;
}

.badge-img-edition {
	position: relative;
	top: -15px;
	;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	//  border: 1px solid #d9d9d9;
	overflow: hidden;
}

.badge-avatar-header {
	display: flex;
	align-items: center;
	border-radius: 50%;
	overflow: hidden;
	width: 40px;
	height: 40px;
	background-color: white;
}


//////////////////////////////////////////////
// Progress bar

.progress-bar-container {
	background-color: #fff;
	display: inline-block;
	border: 1px solid @edition-light-gray;
}

.progress-bar {
	height: 100%;
	// border: 1px solid @edition-primary-orange;
}

//////////////////////////////////////////////
// HEADER
.super-admin-menu {
	.divider {
		border-width: 2;
		border-color: @dark-gray;

		.ant-divider-inner-text {
			padding: 0 10px
		}
	}
}

.edit-profile-menu {
	cursor: pointer;

	.select-lang {
		padding-left: 0px;
		padding-bottom: 5px;
		margin: 0;
		list-style-type: none;
		border-top: 1px solid @edition-light-gray;
		display: flex;
		justify-content: center;
		align-content: center;

		li {
			display: inline-block;
			padding: 0 10px;
		}
	}
}

.notifications-menu {
	height: 50px;
	min-width: 200px;
	padding-top: 10px;


	.no-notif {
		text-align: center;
		opacity: 0.6;
	}
}

.edition-header-menu {
	margin: 0;
	padding: 0;
	height: 100%;
	padding-right: 50px;

	.header-title {

		float: left;
		margin: 0;
		text-transform: uppercase;

		>div {
			width: 100%;
		}
	}

	.vulcan-apps-icon {
		width: 40px;
		height: 40px;
		background-color: @edition-primary-orange;
		border-radius: 50%;

		svg path {
			fill: white;
		}
	}

	.ant-badge-dot {
		background: @edition-primary-orange;
		width: 10px;
		height: 10px;
		box-shadow: none;
	}

}

//////////////////////////////////////////////
// SIDE BAR


.vulcan-sidebar-menu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	background-color: @edition-medium-gray;
	padding: 5px;

	.vulcan-logo {
		margin: 0 10px;

		path {
			fill: @edition-primary-orange;
		}
	}

	.sidebar-item-container {
		margin-bottom: 20px;
	}

	.sidebar-item-organization {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	.sidebar-item-custom-divider {
		border-bottom: 1px solid @grey-vulcan;
		width: 80%;
		margin: 15px auto;
	}

	.sidebar-item {
		display: flex;
		align-items: center;
		padding: 10px;
		cursor: pointer;
		max-width: 260px;

		.sidebar-item-text {
			color: @grey-vulcan;
			margin-left: 10px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}

		svg {
			margin: 0 10px;
			width: 35px;

			path {
				fill: @edition-primary-orange;
			}
		}

		&:hover {
			background-color: #fff;
			color: @edition-primary-orange;
			padding: 10px;
			border-radius: 5px;

			.sidebar-item-text {
				color: @edition-primary-orange;
			}

			path {
				fill: @edition-primary-orange;
			}
		}

		&.active {
			background-color: @edition-primary-orange;
			color: #fff;
			border-radius: 5px;
			-webkit-box-shadow: 0px 0px 5px 0px @edition-primary-orange;
			-moz-box-shadow: 0px 0px 5px 0px @edition-primary-orange;
			box-shadow: 0px 0px 5px 0px @edition-primary-orange;

			.sidebar-item-text {
				color: #fff;
			}

			path {
				fill: #fff;
			}
		}
	}

	.sidebar-item-legal-agreements {
		display: flex;
		align-items: center;
		padding: 10px;

		&:hover {
			background-color: #fff;
			color: @edition-primary-orange;
			padding: 10px;
			border-radius: 5px;
		}

		&.active {
			background-color: @edition-primary-orange;
			color: #fff;
			border-radius: 5px;
		}
	}

}

//////////////////////////////////////////////
// LISTS

.edition-list-header {
	list-style-type: none;
	padding: 0;

	.anticon:hover {
		color: @edition-primary-orange;
	}

	.vulcanicon:hover {
		path {
			fill: @edition-primary-orange;
		}
	}
}

.grouped-actions-btn {
	font-size: 24px;
}

.ant-skeleton.ant-skeleton-element.ant-skeleton-active {
	width: 100%
}

//////////////////////////////////////////////
// ACTION MENU
//     .ant-popover-inner-content {
//         padding-bottom: 1px;
// }

//////////////////////////////////////////////
// USERS INVITATION

// Import Csv File
.custom-tabs .ant-tabs-tab-active {
	background-color: @edition-primary-orange !important;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #fff !important;
}

//////////////////////////////////////////////
// EXERCISES LIST

.exercises-list-tooltip {
	& .ant-tooltip-arrow-content {
		background-color: rgba(255, 255, 255, 0.75);
		;
	}

	& .ant-tooltip-inner {
		text-align: center;
		max-width: 500px;
		background-color: white;
		// border: 1px solid @grey-vulcan;
	}
}

.exercises-list-learning-var {

	svg {
		width: 40px;

		path,
		rect {
			fill: white
		}
	}

	margin: 0 10px;

	&.disabled {
		opacity: 0.3;
	}
}

.exercises-list-skills {
	svg path {
		fill: @grey-vulcan;
	}
}

.exercise-validation-threshold.ant-slider {

	.ant-slider-rail {
		background-color: @edition-light-green;
	}

	.ant-slider-track {
		background-color: #e1e1e1;
	}
}

.exercise-inclusion-threshold.ant-slider {

	.ant-slider-rail {
		background-color: #e1e1e1;
	}

	.ant-slider-track {
		background-color: @red-vulcan;
	}
}

.coef-threshold.ant-slider {

	.ant-slider-track,
	.ant-slider-rail {
		background-color: #e1e1e1;
	}
}

.learning-var {
	svg {
		width: 25px;

		path,
		rect {
			fill: @grey-vulcan
		}
	}

	&.disabled {
		opacity: 0.3;
	}
}

//////////////////////////////////////////////
// LICENSES

.license-status-circle {
	width: 17px;
	height: 17px;
	border-radius: 50%;
	display: inline-block;
}

.license-status {
	position: relative;
	padding-left: 30px;
	margin-right: 20px;

	&::before {
		content: "";
		width: 20px;
		height: 20px;
		border-radius: 50%;
		position: absolute;
		top: calc((50%) - 10px);
		left: 0;
	}

	&.license-activated-status {
		color: @edition-light-green;

		&::before {
			background: @edition-light-green;
		}
	}

	&.license-new-status {
		color: @grey-vulcan;

		&::before {
			background: @grey-vulcan;
		}
	}

	&.license-disabled-status,
	&.license-revoked-status {
		color: @red-vulcan;

		&::before {
			background: @red-vulcan;
		}
	}

	&.license-suspended-status {
		color: @yellow-vulcan;

		&::before {
			background: @yellow-vulcan;
		}
	}
}

.ant-input-disabled {
	color: @grey-vulcan;
	cursor: text;
}

.default-simulator-logo {
	font-size: 40px;

	& svg {
		border-radius: 50%;
	}
}

.dashboard-license-modules-nested-table {

	&.license-table {

		.ant-table table {
			border-spacing: 0 20px;
			border-collapse: separate;
		}

		.ant-table-thead>tr>th,
		.ant-table-tbody>tr>td {
			word-break: normal;
			padding-left: 8px;
		}

		thead tr th {
			background: transparent;
		}

		.ant-table-thead>tr>th.items-list-col {
			padding: 16px 8px;
		}

		.ant-table-expanded-row {

			background: transparent;
			border-spacing: 0;

			&:hover>td {
				background: transparent;
			}

			td {
				background: transparent;
				border: none;
			}
		}

		.nested-exercices-list {
			.ant-table table {
				border-spacing: 0;
			}

			.ant-table .ant-table-content {

				.ant-table-tbody>tr {
					background-color: #f2f2f2;
					height: auto;

				}
			}
		}
	}


}

//////////////////////////////////////////////
// STORE

.store-navigation {
	text-transform: uppercase;
	font-size: 20pt;

	a {
		color: @grey-vulcan;
		margin-right: 30px;
	}
}

// Remove plugin card title margin bottom
.store-plugin-card {
	.ant-card-meta-detail>div:not(:last-child) {
		margin-bottom: 0;
	}
}

// ★★★★★ Star ratings
.star-ratings {
	unicode-bidi: bidi-override;
	color: #ccc;
	position: relative;
	margin: 0;
	padding: 0;

	.fill-ratings {
		color: @edition-primary-orange;
		padding: 0;
		position: absolute;
		z-index: 1;
		display: block;
		top: 0;
		left: 0;
		overflow: hidden;

		// Allows us to grab the width of the span elements
		span {
			display: inline-block;
		}
	}

	.empty-ratings {
		padding: 0;
		display: block;
		z-index: 0;
	}
}

// Plugin medias carousel
.plugin-carousel-container {
	width: 100%;
}

.plugin-carousel-item {
	padding: 0 20px;
	width: auto !important;

	.ant-image-img {
		width: auto;
	}
}

.text-display-root .text-display {
	overflow: hidden;
	transition: height 0.5 ease-in-out;
}

//////////////////////////////////////////////
// LEARNING PATH

.training-path-edit-wrapper {
	.popup-wrapper {

		.items-list-wrapper {
			padding: 0;

			.items-list-table {
				padding: 0 !important;
			}
		}
	}

	.exercises-title-list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		.exercises-title {
			float: left;
			margin: 0;
		}

		li {
			float: left;
			height: 100%;
			margin: 0 15px;
			display: flex;
			align-items: center;
		}
	}
}

.exercises-cards-list-wrapper {
	padding-top: 20px;

	.exercise-card {
		position: absolute;
		border-radius: 5px;
		background-color: white;
		border: 1px solid @mid-gray;
		padding: 5px;
		cursor: pointer;
		user-select: none;
		-moz-user-select: none;

		.bottom {
			// position: absolute;
			// left: 0;
			list-style-type: none;
			margin: 0;
			padding: 0;

			.order {
				float: left;
				margin: 0;
			}
		}

		.ant-btn {
			padding: 0;
		}

		.type-icon {
			svg path {
				fill: @dark-gray;
			}
		}

		.add-item-border {
			//  border: 2px dashed @grey-vulcan;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				fill: @edition-primary-orange;
			}

			.plus-icon {
				font-size: 30px;
				color: white;
			}
		}
	}

	.exercise-card .add-btn {
		border: 1px dashed @mid-gray;
		background-color: @edition-light-gray;
	}

}

.exercises-management-wrapper {

	.popup-wrapper {

		.items-list-wrapper {
			padding: 0;

			.items-list-table {
				padding: 0 !important;
			}
		}
	}

	.exercises-title-list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		// height: 50px;

		.exercises-title {
			float: left;
			margin: 0;
		}
	}
}

.roles-table {
	thead {
		display: none;
	}

	.ant-table-thead>tr.ant-table-row-hover>td,
	.ant-table-tbody>tr.ant-table-row-hover>td,
	.ant-table-thead>tr:hover>td,
	.ant-table-tbody>tr:hover>td {
		background-color: transparent;
	}

	.role-list {
		list-style-type: none;
		margin: 0;
		padding: 0;

		.role-icon {
			float: left;
			margin: 0;
		}
	}
}


//////////////////////////////////////////////////////////////

// SUBSCRIPTION
.subcription-container {
	border-radius: 5px;
	box-shadow: @vulcan-box-shadow;

	.header {
		background-color: @edition-primary-orange;
		border-radius: 5px 5px 0 0;
		color: #FFFFFF;
		font-size: 24px;
	}

	.content {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;

		.description {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}

///////////////////////////////////////////////
// ORDERS STORE


.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>.items-list-col .SVGInline.SVGInline.new-status {
	display: inline-block;
	text-align: center;
	background-color: #d4b138;
	border-radius: 50px;
	width: 30px;
	height: 30px;
	padding-top: 10px;

	svg.SVGInline-svg g path {
		fill: white
	}
}

.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>.items-list-col .SVGInline.SVGInline.cancelled-status {
	display: inline-block;
	background-color: #ff4330;
	border-radius: 50px;
	width: 30px;
	height: 30px;
	padding-top: 10px;

	svg.SVGInline-svg path {
		fill: white
	}
}

.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>.items-list-col .SVGInline.SVGInline.accepted-status {
	display: inline-block;
	background-color: #a2c89b;
	border-radius: 50px;
	width: 30px;
	height: 30px;
	padding-top: 10px;

	svg.SVGInline-svg path {
		fill: white
	}
}

.orders-products-wrapper {
	.orders-product-icon {
		padding: 6px 5px;
		margin-right: 5px;
		background-color: @edition-primary-orange;
		border-radius: 50px;
		width: 30px;
		height: 30px;
	}
}

// Vulcan logo color
.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>.items-list-col .orders-products-wrapper .SVGInline .SVGInline-svg path {
	fill: white
}

.order-form-wrapper {
	font-size: 18px;

	label {
		font-size: 18px;
	}
}

.order-edit-status-wrapper {

	.SVGInline.new-status {
		display: inline-block;
		text-align: center;
		background-color: #d4b138;
		border-radius: 50px;
		width: 20px;
		height: 20px;
		padding-top: 5px;
		margin-right: 5px;

		svg.SVGInline-svg g path {
			fill: white
		}
	}

	.SVGInline.cancelled-status {
		display: inline-block;
		text-align: center;
		background-color: #ff4330;
		border-radius: 50px;
		width: 20px;
		height: 20px;
		padding-top: 5px;
		margin-right: 5px;

		svg.SVGInline-svg path {
			fill: white
		}
	}

	.SVGInline.accepted-status {
		display: inline-block;
		text-align: center;
		background-color: #a2c89b;
		border-radius: 50px;
		width: 20px;
		height: 20px;
		padding-top: 5px;
		margin-right: 5px;

		svg.SVGInline-svg path {
			fill: white
		}
	}
}

.profile-img-circle {
	width: inherit;
	height: inherit;
	border-radius: inherit;
	object-fit: contain;
}

.round-avatar-bg {
	background-color: white;
	margin-right: 10px;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.avatar-img-circle {
	width: 40px;
	height: auto;
	border-radius: inherit;
	object-fit: contain;
	position: relative;
	// left: 8px;
	// top: 5px;
}

.learning-path-title {
	display: flex;
	align-items: center;

	.learning-path-type {
		height: 25px;
		margin-right: 10px;
		position: relative;
	}
}


.ico-default::before {
	content: '';
	width: 15px;
	height: 15px;
	background-image: url('../images/icons/ico-protected.svg');
	position: absolute;
	bottom: 0;
	right: -2px;
}

.ico-default {
	display: inline-block;
}

.exercise-title {

	.exercise-type {
		width: 25px;
		margin-right: 10px;
		position: relative;
	}
}

.number-circle {
	border-radius: 50%;
	display: inline-block;
	width: 30px;
	height: 30px;

	background: @mid-gray;
	color: white;
	text-align: center;
	vertical-align: middle;

	div {
		padding-top: 2px;
	}
}

/////////////////////////////////////////////////
// User Results

.results-user-header {
	background-color: white;
	box-shadow: @vulcan-box-shadow;
	box-sizing: border-box;
	width: 100%;
	margin: 10px auto;
	border-radius: 10px;
	border: 1px solid #D6D6D6;

	div .results-user-informations {
		display: flex;
		flex-direction: column;
		margin: 10px 0 0 15px;

	}

}

.results-learning-path {
	box-sizing: border-box;
	border-radius: 10px;
	border: 1px solid #D6D6D6;
	padding: 20px;

	// .exercices-dot {
	// 	background-color: white;
	// 	border-radius: 0 10px 10px 0;
	// }
}

/////////////////////////////////////////////////
// User Exercise Results Details

.result-details-header {
	position: relative;
	margin: 40px 0;
	max-width: 100%;

	.image-cover {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 10px;
	}

	.user-result-details {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 20px;

		.user-score {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			width: 100%;
			height: 100%;
			align-items: flex-end;
			justify-content: center;
			font-size: 40px;
			font-weight: bold;
		}

		.user-result-details-tile-informations {
			margin-top: 10px;
			text-align: center;
			// background-color: rgba(255, 255, 255, 0.3);
			padding: 20px;
		}
	}

}

.result-details-tile {
	background: white;
	box-shadow: @vulcan-box-shadow;
	box-sizing: border-box;
	padding: 20px;
	color: @grey-vulcan;
	border-radius: 10px;
	margin-bottom: 30px;
}

.result-details-tile-title {
	display: flex;
	align-items: center;
	font-weight: normal;
	line-height: 1;

	/* Icons */

	.anticon {
		margin-right: 10px;
	}
}

// Sensors' progress bar
.result-details-progress-bar .ant-progress-outer {
	width: 100%;

}

.result-details-progress-bar .ant-progress-inner {
	height: 1em;
	background-color: #ffc7b2;
}

.result-details-progress-bar .ant-progress-bg {
	transform-origin: bottom;
	height: 1em !important;
}

// Events
.ant-timeline-item:last-child {
	padding: 0;
}

.ant-timeline-item-last>.ant-timeline-item-content {
	min-height: 0;
}

/////////////////////////////////////
// EDITOR PORTAL general

.editor-h1 {
	color: @grey-vulcan;
	margin-bottom: 1em;
}

/////////////////////////////////////
// EXERCISE CREATION

// Exercise and result cover image
.exercise-upload-cover-image>.ant-upload {
	width: 100%;
	height: 200px;
}

.exercise-section-title {
	margin-top: 40px;
	margin-bottom: 10px;
	margin-left: 10px;
}

.exercise-add-button {
	display: flex;
	align-items: center;
	cursor: pointer;
	border: 2px dashed @grey-vulcan;
	height: 50px;
}

.exercise-steps-panel-tile {
	background-color: #ececec;
	height: 300px;
	padding: 10px;
	margin-bottom: 20px;
	overflow-y: auto;
}

/////////////////////////////////////
// LEARNING PATH CREATION

// Container section
.learning-path-container-section {
	box-shadow: @vulcan-box-shadow;
	background-color: #FFFFFF;
	margin-left: 10;
	padding: 20px 40px;
}

.learning-path-table-add-exercises thead>tr>th {
	background-color: white !important;
}

// Exercise drop after in learning path creation
tr.drop-over-downward td {
	border-bottom: 2px dashed @edition-primary-orange;
}

// Exercise drop before in learning path creation
tr.drop-over-upward td {
	border-top: 2px dashed @edition-primary-orange;
}

/////////////////////////////////////
// ORGANIZATION CREATION

.type-button {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.type-desc {
	color: #adadad;
	font-style: italic;
	font-size: 15px;
}

.orga-details {
	.ant-collapse-header {
		color: @edition-primary-orange !important;
		padding-top: 0 !important;
		padding-left: 0 !important;
	}
}

/////////////////////////////////////
// SIMULATORS

.simulator-card {

	cursor: initial;

	// Icon size
	.ant-card-actions>li>span>.anticon {
		font-size: 18px;
	}

	// For all the cards to have same body height
	.ant-card-body {
		flex-grow: 1;
	}
}

// Image container
.img-hover-zoom {
	width: 300px;
	overflow: hidden;
}

// Transition property for smooth transformation of images
.img-hover-zoom img {
	transition: transform 1s ease;
}

// Image zoom
.simulator-card:hover .img-hover-zoom img {
	transform: scale(1.3);
}

// # -------- Create form

.simulator-creation-steps {

	// Required step with *
	.required-step::before {
		display: inline-block;
		margin-right: 4px;
		color: #ff4d4f;
		font-size: 16px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}

	// Lines
	.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after,
	.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after,
	.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
		background-color: @dark-gray;
	}

	// Line margin
	&.ant-steps-dot .ant-steps-item-tail {
		margin: 0 0 0 3px;
	}

	&.ant-steps-dot .ant-steps-item-content {
		margin-left: -67px;
		width: 150px;
	}

	// First dot
	.ant-steps-item:first-of-type>.ant-steps-item-container>.ant-steps-item-icon {
		width: 11px;
		height: 11px;
		top: -2px;
		left: 7px;
	}

	// Last dot
	.ant-steps-item:last-of-type>.ant-steps-item-container>.ant-steps-item-icon {
		width: 11px;
		height: 11px;
		top: -2px;
		left: -7px;
	}

	// Other dots
	&.ant-steps-dot .ant-steps-item-icon,
	&.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
		width: 32px;
		height: 32px;
		margin-left: 0;
		padding-right: 0;
		line-height: 8px;
		background: @dark-gray;
		border: 0;
		position: relative;
		top: -13px;
		left: -11px;
	}

	// Icons
	.ant-steps-item-icon>.ant-steps-icon {
		top: 6px;
		font-size: 20px;
		color: white;
	}

	// Hover & finished step
	&.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process):not(:first-of-type):not(:last-of-type)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon,
	&.ant-steps-dot .ant-steps-item-finish .ant-steps-item-icon,
	&.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
	&.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {

		background-color: @edition-primary-orange;

		// Icon
		.ant-steps-icon {
			color: white;
		}
	}

	// Active step (navigation)
	&.ant-steps-dot .ant-steps-item-active .ant-steps-item-icon,
	&.ant-steps-dot .ant-steps-item-process.ant-steps-item-active .ant-steps-item-icon {
		border: 2px solid @edition-primary-orange;
		background-color: white;

		// Icon
		.ant-steps-icon {
			top: 4px;
			color: @edition-primary-orange;
		}
	}

	// Active step text
	.ant-steps-item-active>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
		color: @edition-primary-orange;
	}

	// Last no pointer
	&.ant-steps .ant-steps-item:not(.ant-steps-item-active):first-of-type>.ant-steps-item-container[role='button'] // , &.ant-steps .ant-steps-item:not(.ant-steps-item-active):last-of-type > .ant-steps-item-container[role='button']

		{
		cursor: initial;
	}
}

// Glance box
.simulators-create-glance {
	background-color: white;
	box-shadow: @vulcan-box-shadow;
	padding: 20px;

	// Underline
	h3 {
		border-bottom: 2px solid @light-color-vulcan;
	}
}

// Footer panel
.simulator-create-footer {
	background-color: white;
	box-shadow: @vulcan-box-shadow;
	margin-top: 40px;

	.ant-row {
		height: 140px;
	}

}

// Simulator steps pane
.simulator-create-header-steps {
	background-color: white;
	box-shadow: @vulcan-box-shadow;
	height: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
}

// Simulator logo
.simulator-upload-logo>.ant-upload {
	width: 200px;
	height: 200px;
	border-radius: 100%;
}

// Simulator cover image
.simulator-upload-cover-image>.ant-upload {
	width: 100%;
	height: 200px;
}

// Popover width
.simulator-creation-popover .ant-popover-inner-content {
	max-width: 500px;
}

// Global wrapper for simulator creation form
.simulator-creation-form {
	max-width: 1000px;
	margin: auto;
}

// Sensor icon
.simulator-upload-sensor-icon>.ant-upload {
	width: 120px;
	height: 120px;
}

// Skills contribution table
.skills-contribution-table {

	border-collapse: separate;

	td {
		background-color: white;
		border: 1px solid #d9d9d9;
		box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
	}

	// First table header (empty)
	th:first-child {
		visibility: hidden;
		border: none;
	}

	// Skill icon and name
	td:first-child {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
	}

	// Coef
	td:nth-child(2) {
		text-align: center;
	}
}

// Sim creation overview
.simulator-creation-overview {

	// Logo
	.simulator-creation-overview-logo {
		border-radius: 100%;
		box-sizing: content-box;
		border: 2px solid white;
		position: relative;
		top: -40px;
		left: 20px;
		object-fit: cover;
	}

	// Label
	label {
		color: #bbbbbb
	}

	// Values
	.overview-text {
		font-weight: 600;
		margin-bottom: 10px;
	}

	// Collapse Panel Headers
	.custom-header .ant-collapse-header {
		color: @grey-vulcan;
		border: 1px;
	}
}

// -------- Simulator locales table

.simulator-locales-informations {
	border: 1px solid #D6D6D6;
	border-radius: 10px;
	margin-left: 20px;
	padding: 20px;
}

// Search input in Modal
.simulator-locales-search-input {

	// Search icon/button
	.ant-input-search-button {
		margin-bottom: 20px;
		border: 0;
		box-shadow: none;
	}

	// Weird background after search button
	.ant-input-group-addon {
		background: transparent;
	}
}

// // Locale in list
.simulator-locale-item {
	display: flex;
	align-items: center;
	height: 50px;
	width: 100%;
	text-align: left;
	font-size: 18px;
	border-radius: 15px;
	border: none;
}

// -------- Simulator Import/Export
.simulator-mask {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 998;
	background-color: rgba(0, 0, 0, 0.5);
}

.simulator-loading {
	position: fixed;
	top: 50%;
	left: 50%;
	z-index: 999;
	color: #FFFFFF;

	.loader {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 30px;
	}
}

/////////////////////////////////////
// DISTRIBUTOR PORTAL

// LICENSE DETAILS
.license-details-drawer {
	padding: 0 20px 20px 20px;

	h3,
	h4 {
		color: #4B4B4B;
		font-weight: bold;
	}

	.license-details-drawer-title-item {
		color: #4B4B4B;
	}
}

// LICENSE CREATION
.license-create-tile {
	box-shadow: @vulcan-box-shadow;
	background-color: #FFFFFF;
	margin-left: 10;
	padding: 20px 40px;
}

.license-create-module-tree {
	.ant-tree-node-content-wrapper {
		cursor: default;
	}
}
@primary-color: #ff4701;@font-size-base: 16px;@text-color: #898989;@font-family: Titillium, Roboto, sans-serif;@code-family: Titillium, Roboto, sans-serif;@layout-body-background: transparent;@layout-header-height: 145px;@avatar-border-radius: 0px;@menu-item-color: #000;@menu-highlight-color: #fff;@menu-item-active-bg: @primary-color;