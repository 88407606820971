/* Extralight */
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }
/* Light */
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }
/* Regular */
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }
/* Medium */
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }
 /* Bold */
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }
 /* Bold */
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }
/*
@font-face {
  font-family: Titillium;
  src: url("./TitilliumWeb-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }
*/
@primary-color: #ff4701;@font-size-base: 16px;@text-color: #898989;@font-family: Titillium, Roboto, sans-serif;@code-family: Titillium, Roboto, sans-serif;@layout-body-background: transparent;@layout-header-height: 145px;@avatar-border-radius: 0px;@menu-item-color: #000;@menu-highlight-color: #fff;@menu-item-active-bg: @primary-color;