/* Thin */
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }
/* Light */
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }
/* Regular */
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }
/* Medium */
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }
 /* Bold */
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }
 /* Bold */
@font-face {
  font-family: Roboto;
  src: url("./Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }
@font-face {
  font-family: Roboto;
  src: url("./Roboto-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@primary-color: #ff4701;@font-size-base: 16px;@text-color: #898989;@font-family: Titillium, Roboto, sans-serif;@code-family: Titillium, Roboto, sans-serif;@layout-body-background: transparent;@layout-header-height: 145px;@avatar-border-radius: 0px;@menu-item-color: #000;@menu-highlight-color: #fff;@menu-item-active-bg: @primary-color;